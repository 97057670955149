import React from 'react';
import ProductSync from './ProductSync';
import MetaStatusCard from './MetaStatusCard';
const ActiveStateCard = ({ storeInfo, onDisconnect }) => {

    const currentUrl = window.location.href;

    return (
        <div className="a-card a-card--normal">
            <div className="a-card__paddings">
                <div className="feature-element has-picture">
                    <div className="feature-element__core">
                        <div className="feature-element__data">
                            <div className="feature-element__title">
                                Your App is Active & Ready to Sync 🎉
                            </div>
                            <div className="feature-element__status feature-element__status--success" aria-hidden="true" style={{ display: 'none' }}>
                                <span className="feature-element__status-title"></span>
                                <div className="feature-element__status-dropdown-container"></div>
                            </div>
                            <div className="a-card a-card--normal">
                                <div className="a-card__paddings">
                                    <div className="text-default">Store ID: {storeInfo.storeId}</div>
                                    {/* <div className="text-default">Access Token: {storeInfo.accessToken}</div> */}
                                    <div className="text-default">Language: {storeInfo.language}</div>
                                    {/* <div className="text-default">View Mode: {storeInfo.viewMode}</div>
                                    {storeInfo.publicToken && <p>Public Token: {storeInfo.publicToken}</p>}
                                    {storeInfo.appState && <p>AppState: {storeInfo.appState}</p>} */}
                                    {/* <div className="text-default">  <p>URL: {currentUrl}</p></div> */}
                                </div>
                            </div>
                            <div className="feature-element__content">
                                <div className="feature-element__text">
                                    Click the button below to synchronize all products from your store with Meta and Libromi.
                                    Please note that the synchronization process may take a few minutes to complete.
                                </div>

                                <ProductSync storeInfo={storeInfo} onDisconnect={onDisconnect} />
                                {/* <button type="button" className="btn btn-primary btn-medium feature-element__button">
                                    Sync Products
                                </button> */}

                                <div>

                                    {/* {message && <div className="sync-message">{message}</div>} */}
                                </div>
                            </div>
                        </div>
                        <div >
                            <img
                                className="gwt-Image"
                                src="https://libromi.com/wp-content/uploads/2024/04/img.png"
                                style={{ height: '180px' }}  // Using an object for inline styles
                                alt="Libromi WhatsApp Store"
                            />
                            <MetaStatusCard storeInfo={storeInfo} />
                            <hr className="mobile-only" style={{ margin: '20px 0' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActiveStateCard;
