import React from 'react';

const PromoBlock = () => {
  return (
    <div className="promo-block">
      <div className="promo-block__picture">
      <img src={`${process.env.PUBLIC_URL}/wc-catalog.png`} alt="Catalog" />
      </div>
      <div className="promo-block__features">
        <ul className="titled-items-list">
          <li className="titled-item titled-items-list__item">
            <div className="titled-item__title">Step 1</div>
            <div className="titled-item__content">
              Sign Up for an account on <a href="https://libromi.com" target='_blank' rel="noopener noreferrer">Libromi.com</a> and subscribe to a paid package
            </div>
            <a href="https://libromi.com" target="_blank" rel="noopener noreferrer" className="btn btn-default btn-small">
              Sign Up
            </a>
          </li>
          <li className="titled-item titled-items-list__item">
            <div className="titled-item__title">Step 2</div>
            <div className="titled-item__content">
              Create a Meta Catalog and setup eCommerce Settings and enter the Token below
            </div>
            <a href="https://youtu.be/kiRYvW8YW7M" target="_blank" rel="noopener noreferrer" className="btn btn-default btn-small">
              Watch Tutorial Video
            </a>
          </li>
          {/* Repeat other list items */}
        </ul>
      </div>
    </div>
  );
};

export default PromoBlock;
