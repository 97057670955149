import logo from './logo.svg';
import './App.css';
import EcwidStoreInfo from './components/EcwidStoreInfo';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <div class="settings-page__body">
      <EcwidStoreInfo />
      </div>
      </header>
    </div>
  );
}

export default App;
