import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import ConfigForm from './ConfigForm';
import StoreInfoDisplay from './StoreInfoDisplay';
import Notification from './Notification';
import ActiveStateCard from './ActiveStateCard';
const EcwidStoreInfo = () => {
    const [storeInfo, setStoreInfo] = useState({
        storeId: '',
        accessToken: '',
        language: '',
        viewMode: '',
        publicToken: '',
        appState: ''
    });
    const [configured, setConfigured] = useState(false);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [isEcwidAppReady, setIsEcwidAppReady] = useState(false);
    const initializedRef = useRef(false);
    const backendUrl = process.env.REACT_APP_BASE_URL;
    const ecwidSecret = process.env.REACT_APP_CLIENTSECRECT;
    const appId = process.env.REACT_APP_APP_ID;
    // Ecwid App initialization
    useEffect(() => {
        if (!initializedRef.current && window.EcwidApp) {
            window.EcwidApp.init({
                app_id: appId,
                autoloadedflag: true,
                autoheight: true
            });
            setIsEcwidAppReady(true);
            initializedRef.current = true;  // Mark as initialized
        }
    }, []);  // Empty dependency array to run only once

    useEffect(() => {
        const fetchStoreInfo = async () => {
            if (!isEcwidAppReady) return;

            try {
                const response = await axios.post(`${backendUrl}/ecwid/decrypt-payload`, {
                    payload: new URLSearchParams(window.location.search).get('payload'),
                    clientSecret: ecwidSecret  // Replace with actual client secretk
                });
                const data = response.data;
                setStoreInfo({
                    storeId: data.store_id,
                    accessToken: data.access_token,
                    language: data.lang,
                    viewMode: data.view_mode,
                    publicToken: data.public_token || '',
                    appState: new URLSearchParams(window.location.search).get('app_state') || ''
                });
                setLoading(false);  // Ensure to set loading to false here
            } catch (error) {
                console.error("Error fetching store info:", error);
                setLoading(false);  // Ensure to handle loading state properly
            }
        };

        fetchStoreInfo();
    }, [isEcwidAppReady]);

    // Effect for checking configuration
    useEffect(() => {
        if (isEcwidAppReady && storeInfo.storeId) {
            console.log("Store ID:", storeInfo.storeId);
            setLoading(true);
            const checkConfig = async () => {
                console.log("Checking configuration...");
                try {
                    const response = await axios.get(`${backendUrl}/ecwid/check-config`, {
                        params: { storeId: storeInfo.storeId }
                    });
                    setConfigured(response.data);
                    console.log("Configuration checked:", response.data);
                } catch (error) {
                    console.error("Error checking configuration:", error);
                }
                setLoading(false);
            };

            checkConfig();
        }
    }, [isEcwidAppReady, storeInfo.storeId]); // Now depends on both `isEcwidAppReady` and `storeInfo.storeId`


    const handleTokenSubmit = async (event) => {
        event.preventDefault();
        const userProvidedAccessToken = event.target.accessToken.value;  // This is the token input by the user in the form

        try {
            const response = await axios.post(`${backendUrl}/ecwid/update-config`, {
                storeId: storeInfo.storeId,
                accessToken: storeInfo.accessToken  // Passing accessToken from storeInfo state
            }, {
                headers: {
                    'Authorization': `Bearer ${userProvidedAccessToken}`  // Using user-provided token for authorization
                }
            });
            console.log("Configuration updated:", response.data);
            setMessage(response.data.message);
            setConfigured(true);
        } catch (error) {
            console.error("Error updating configuration:", error);

            setConfigured(false);  // Update accordingly based on the response
            //reload the react component 
            // window.location.reload();
            setMessage('Failed to update configuration. Please provide a valid access token.');
            
        }
    };

    const handleDisconnect = async () => {
        // setLoading(true);
        // setMessage("Disconnecting...");
        setConfigured(false);
   
    }

    if (loading) {
        return <div className="spinner">Loading...</div>;
    }

    const clearMessage = () => setMessage(''); // Clear message to hide notification

    
    return (
        <div>
            <Notification message={message} onClose={clearMessage} />
            {!configured ? (
                <div className="named-area__body">
                    <ConfigForm onSubmit={handleTokenSubmit} />
                    <p>{message}</p>
                </div>
            ) : (
                // <StoreInfoDisplay storeInfo={storeInfo} />
                <ActiveStateCard storeInfo={storeInfo} onDisconnect={handleDisconnect}/>


            )}
        </div>
    );

};

export default EcwidStoreInfo;
