import React, { useEffect, useState } from 'react';

const Notification = ({ message, type = "success", onClose }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        // Set the notification to visible whenever the message changes and is not empty
        if (message) {
            setVisible(true);
        }

        // Set the timer to hide the notification after 3000 ms
        const timer = setTimeout(() => {
            if (message) {
                onClose(); // Call the onClose to clear the message state
            }
            setVisible(false); // Hide the notification after the timer
        }, 3000);

        return () => clearTimeout(timer); // Cleanup the timer on component unmount or on re-render
    }, [message, onClose]);

    // Only render the notification if it is set to visible
    if (!visible || !message) return null;

    return (
        <div>
        </div>
    );
};

export default Notification;
