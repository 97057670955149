import React from 'react';
import SettingsPage from './landingPage/SettingsPage';

const ConfigForm = ({ onSubmit }) => {
    const handleFocus = (event) => {
        const parent = event.target.parentNode;
        parent.classList.add('field--focus');
        parent.querySelector('.field__placeholder').style.opacity = '1';
    };

    const handleBlur = (event) => {
        const parent = event.target.parentNode;
        if (!event.target.value) {
            parent.classList.remove('field--filled');
            parent.querySelector('.field__placeholder').style.opacity = '0';
        }
        parent.classList.remove('field--focus');
    };

    // const handleInput = (event) => {
    //     const parent = event.target.parentNode;
    //     if (event.target.value) {
    //         parent.classList.add('field--filled');
    //         parent.querySelector('.field__placeholder').style.opacity = '1';
    //     } else {
    //         parent.classList.remove('field--filled');
    //         parent.querySelector('.field__placeholder').style.opacity = '0';
    //     }
    // };

    return (
        <div className="a-card a-card--normal">
        <div className="a-card__paddings">
             <SettingsPage />
        <form onSubmit={onSubmit} className="fieldset">
            <div class="text-small">Get the Access Token from the eCommerce Settings of Libromi</div>
            <div className="field field--medium">
                <label className="field__label">Enter Access Token</label>
                <input
                    type="text"
                    className="field__input"
                    name="accessToken"
                    placeholder="Enter Access Token"
                    required
                    // onFocus={handleFocus}
                    // onBlur={handleBlur}
                    // onInput={handleInput}
                />
                {/* <div className="field__placeholder">Enter Access Token</div> */}
            </div>
            <button type="submit" className="btn btn-primary btn-medium" style={{ marginTop: '10px' }}>Activate</button>
        </form>
        </div>
        </div>
    );
};

export default ConfigForm;
