import React from 'react';
import PromoBlock from './PromoBlock';

const SettingsPage = () => {
  return (
      <div className="settings-page__header">

        <div className="settings-page__titles">
          <h1 className="settings-page__title">🚀 Activate WhatsApp Store</h1>
          <div className="settings-page__subtitle">
          Enable customers to browse and place orders directly through WhatsApp. This backend will serve as your control panel for managing products and orders.
          Follow the below steps and launch your WhatsApp store in minutes.
          </div>
        </div>
        {/* <div className="settings-page__cta-wrapper">
          <div className="settings-page__cta-description">
          <div class="text-default warning text-big">  This feature is only work with the paid plan of <a href="https://libromi.com" target='_blank'>Libromi.com</a></div>
          </div>
        </div> */}
        <PromoBlock />
      </div>
  );
};

export default SettingsPage;
