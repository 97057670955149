import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const MetaStatusCard = ({ storeInfo }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // Use environment variable for the backend URL
    const backendUrl = process.env.REACT_APP_BASE_URL



    const fetchData = useCallback(async () => {
        if (!storeInfo || !storeInfo.storeId || !storeInfo.accessToken) {
            console.error("storeInfo is missing or incomplete:", storeInfo);
            setError('Store information is incomplete. Check configuration.');
            setLoading(false);
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(`${backendUrl}/ecwid/meta/status`, {
                storeId: storeInfo.storeId,
                accessToken: storeInfo.accessToken
            });

            console.log('Response:', response.data);
            setData(response.data);
            if (!response.data.isSetCredentials) {
                setError('Please connect Meta Catalog on Libromi before syncing.');
            } else if (!response.data.status && response.data.error && response.data.error.code >= 400) {
                setError("Meta Catalog Permission missing. Please reconnect Meta Catalog on Libromi.");
            } else {
                setError(null);
            }
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('Something went wrong. Try again after some time or contact Libromi support.');
        } finally {
            setLoading(false);
        }
    }, [storeInfo, backendUrl]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className="card">
            {loading ? (
                <div className="skeleton">Loading...</div>
            ) : error ? (
                <div className="a-card a-card--compact a-card--warning" style={{ maxWidth: '250px' }}>
                    <div className="cta-block__central">
                        <div className="cta-block__content" style={{fontSize:'12px', padding: '5px'}}>{error}</div>
                    </div>
                </div>
            ) : (
                <div className="statistic-grid statistic-grid--row-count-2 statistic-grid--col-count-2">
                    <span className="statistic-grid__title">Total Orders:</span>
                    <span className="statistic-grid__value">{data?.orderCount || 0}</span>
                    <span className="statistic-grid__title">Products Count:</span>
                    <span className="statistic-grid__value">{data?.productsCount || 0}</span>
                </div>
            )}
            <div style={{ marginTop: '10px' }}>
                <button className="btn btn-default btn-small" onClick={fetchData} disabled={loading}>
                    Refresh
                </button>
            </div>
        </div>
    );
};

export default MetaStatusCard;
