import React, { useState } from 'react';
import axios from 'axios';

const ProductSync = ({ storeInfo, onDisconnect }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [message, setMessage] = useState('');
    const [syncStatus, setSyncStatus] = useState(false);
    const backendUrl = process.env.REACT_APP_BASE_URL;

    const handleSyncProducts = async () => {
        setIsLoading(true);
        setMessage("Products syncing...");
        console.log("access token on handle sync products", storeInfo.accessToken)
        try {
            const response = await axios.post(`${backendUrl}/ecwid/syncproducts`, {
                storeId: storeInfo.storeId,
                accessToken: storeInfo.accessToken
            });
            setMessage(response.data.message);
            setSyncStatus(response.data.syncStatus);
        } catch (error) {
            setMessage('Failed to sync products: ' + error.message);
            setSyncStatus(false);
        }

        setIsLoading(false);
    };

    const handleDisconnect = async () => {
        setIsLoading2(true);
        setMessage("Disconnecting...");

        try {
            const response = await axios.post(`${backendUrl}/ecwid/disconnect-platform`, {
                storeId: storeInfo.storeId,
                accessToken: storeInfo.accessToken
            });
            setMessage(response.data.message);
            onDisconnect(); // Parent component should handle the state change
        } catch (error) {
            setMessage('Failed to disconnect: ' + error.message);
        }

        setIsLoading2(false);
    };

    return (
        <div>
            <button onClick={handleSyncProducts} className="btn btn-primary " disabled={isLoading}>
                {isLoading ? 'Syncing...' : 'Sync Products'}
            </button>
            <button onClick={handleDisconnect} className="btn btn-default btn--destructive" style={{ marginLeft: '10px' }} disabled={isLoading2}>
                {isLoading2 ? 'Disconnecting...' : 'Disconnect'}
            </button>
            {message && <div className="sync-message">{message}</div>}
        </div>
    );
};

export default ProductSync;
